gsap.registerPlugin(ScrollTrigger);

class GTFO {
  device: string;

  constructor() {
    this.device = this.getDevice();
    this.setupResizeListener();
  }

  createIntroDom() {
    return new Promise((resolve) => {
      const oldIntro = document.querySelector('.intro-wrapper');
      if (oldIntro) {
        oldIntro.remove();
      }
      const mm = gsap.matchMedia();
      const introWrapper: HTMLDivElement = document.createElement('div');
      introWrapper.className = 'intro-wrapper';
      introWrapper.style.cssText = `
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background: #ececec;
      z-index: 1000;
      display: flex;
      justify-content: center;
      align-items: center;
    `;
      const logoWrapper = document.createElement('div');
      logoWrapper.className = 'logo-wrapper';
      logoWrapper.style.cssText = `
      position: relative;
      padding: 1.5rem;
    `;
      const letters = [
        'https://unpkg.com/gtfo-webflow@1/src/g.svg',
        'https://unpkg.com/gtfo-webflow@1/src/t.svg',
        'https://unpkg.com/gtfo-webflow@1/src/f.svg',
        'https://unpkg.com/gtfo-webflow@1/src/o.svg',
      ];
      letters.forEach((letter) => {
        const img = document.createElement('img');
        img.src = letter;
        img.classList.add('letter');
        img.style.cssText = `
          height: 100px;
          position: absolute;
          transform: translateX(-50%);
        `;
        logoWrapper.appendChild(img);
      });
      const lettersEl = logoWrapper.querySelectorAll('.letter');
      lettersEl[0].style.cssText += `
    top: -100px;
    left: 50%;
    `;
      lettersEl[1].style.cssText += `
    top: 50%;
    right: -80px;
    transform: translateY(-50%);
    `;
      lettersEl[2].style.cssText += `
    top: 100%;
    left: 50%;
    `;
      lettersEl[3].style.cssText += `
    top: 50%;
    left: -80px;
    transform: translateY(-50%);
    `;

      const logo = document.createElement('img');
      logo.src = 'https://unpkg.com/gtfo-webflow@1.2.5/src/star.svg';
      logo.classList.add('logo');
      logoWrapper.appendChild(logo);
      logo.style.cssText = `
        height: 300px;
      `;

      mm.add('(max-width: 767px)', () => {
        logoWrapper.style.cssText += `
          scale: 0.5;
          padding: 3.5rem;
        `;
      });
      introWrapper.appendChild(logoWrapper);

      document.body.appendChild(introWrapper);
      document.body.style.overflowY = 'hidden';

      resolve(introWrapper);
    });
  }
  cropInImage() {
    const heroBg: HTMLDivElement = document.querySelector(
      '.main-wrapper .hero .background-image-wrapper'
    )!;
    if (!heroBg) return;
    heroBg.style.overflow = 'hidden';
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: heroBg.parentElement,
        start: 'center center',
        end: 'bottom center',
        scrub: true,
        markers: false,
      },
    });
    tl.fromTo(
      heroBg,
      { borderRadius: '0rem', margin: '0' },
      { borderRadius: '3rem', margin: '8px 24px', duration: 1 }
    );
  }
  cropOutImage(img: HTMLDivElement, trigger: HTMLDivElement) {
    const style = {
      radius: parseInt(img?.computedStyleMap().get('border-radius')?.toString()),
      left: parseInt(img?.computedStyleMap().get('left')?.toString()),
      right: parseInt(img?.computedStyleMap().get('right')?.toString()),
    };

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: trigger,
        start: 'top center',
        end: 'center center',
        scrub: true,
        markers: false,
      },
    });

    tl.fromTo(
      img,
      {
        borderRadius: style.radius,
        left: style.left,
        right: style.right,
      },
      {
        borderRadius: 0,
        left: 0,
        right: 0,
        duration: 1,
      }
    );
  }
  partnersRowsAnimation() {
    // TODO: Add a bit of velocity
    // TODO: Fix starting point
    const partnersSections = document.querySelectorAll('.partners-banner')!;
    if (partnersSections.length === 0) return;
    partnersSections.forEach((partnersSection) => {
      const rows = partnersSection.querySelectorAll('.partners_row');
      const timelines = Array.from(rows).map((row, i) => {
        const tl = gsap.timeline({ paused: true });
        tl.to(row, { x: `${i % 2 === 0 ? '-' : '+'}400` });
        return tl;
      });

      ScrollTrigger.create({
        trigger: partnersSection,
        start: 'top bottom',
        end: 'bottom top',
        scrub: true,
        markers: false,
        onUpdate: (self) => {
          timelines.forEach((tl) => tl.progress(self.progress));
        },
      });
    });
  }
  testimonials(items: Element[], trigger: HTMLDivElement) {
    if (this.device === 'mobile') return;
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: trigger,
        start: 'top center',
        end: '30% center',
        scrub: true,
        markers: false,
      },
    });
    tl.set(items[0], { zIndex: 1 });
    tl.set(items[2], { zIndex: 2 });
    tl.add('start', 0)
      .fromTo(
        items[0],
        {
          transform: 'rotate(7.85deg)',
        },
        {
          transform: 'rotate(0deg)',
        },
        'start'
      )
      .fromTo(
        items[1],
        {
          transform: 'rotate(-7.12deg) translateX(-60px)',
        },
        {
          transform: 'rotate(0deg) translateX(0px)',
        },
        'start'
      )
      .fromTo(
        items[2],
        {
          transform: 'rotate(-13deg) translate(80px, -230px)',
        },
        {
          transform: 'rotate(0deg) translate(0px, 0px)',
        },
        'start'
      )
      .fromTo(
        items[3],
        {
          transform: 'rotate(7deg) translate(-110px, -220px)',
        },
        {
          transform: 'rotate(0deg) translate(0px, 0px)',
        },
        'start'
      );
  }
  imageBannerAnimation(img: HTMLImageElement, trigger: HTMLElement) {
    if (this.device === 'mobile') return;
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: trigger,
        start: 'center center',
        end: () => `+=${trigger.scrollWidth}`,
        scrub: true,
        markers: false,
        pin: true,
      },
    });
    tl.fromTo(img, { x: '100%' }, { x: '0%' });
  }
  logoSpin(logo: SVGElement, trigger: HTMLElement) {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: trigger,
        start: 'top bottom',
        end: 'bottom top',
        scrub: true,
        markers: false,
      },
    });
    tl.to(logo, { rotate: 360 });
  }
  overlapBannerAnimation(element: HTMLDivElement, wrapper: HTMLDivElement) {
    if (this.device === 'desktop') {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: wrapper,
          start: 'top top',
          end: 'bottom center',
          scrub: true,
          markers: false,
          pin: true,
        },
      });
      tl.add('start', 0);
      tl.fromTo(
        element.querySelector('.image-content_image-wrapper'),
        { top: '100%' },
        { top: '0%' },
        'start'
      ).fromTo(
        element.querySelector('.image-content_content-wrapper'),
        { top: '-100%' },
        { top: '0%' },
        'start'
      );
    } else if (this.device === 'mobile') {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: wrapper,
          start: 'center center',
          end: 'center center',
          toggleActions: 'play none none reverse',
          scrub: false,
          markers: false,
          pin: true,
        },
      });
      tl.add('start', 0);
      tl.fromTo(
        element.querySelector('.image-content_image-wrapper'),
        { opacity: '0' },
        { opacity: '1' },
        'start'
      ).fromTo(
        element.querySelector('.image-content_content-wrapper'),
        { opacity: '0' },
        { opacity: '1' },
        'start'
      );
    }
  }
  horizontalScrollAnimation(element: HTMLDivElement, trigger: HTMLElement) {
    const device = this.getDevice();
    if (device === 'mobile') return;
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: trigger,
        start: 'center center',
        end: () => `+=${trigger.scrollWidth}`,
        scrub: true,
        markers: false,
        pin: true,
      },
    });
    tl.to(element, { x: () => -1 * (trigger.scrollWidth + 100 - window.innerWidth) });
  }
  introAnimation(introWrapper: HTMLDivElement) {
    const logo = introWrapper.querySelector('.logo')!;
    const letters = introWrapper.querySelectorAll('.letter');
    const tl = gsap.timeline({
      onComplete: () => {
        introWrapper.remove();
        document.body.style.overflowY = 'auto';

        gsap.to(document.querySelector('.page-wrapper.is-loading'), {
          opacity: 1,
          onComplete: () => {
            document.querySelector('.page-wrapper')?.classList.remove('is-loading');
          },
        });
        gsap.to(document.querySelector('.page-wrapper'), {
          overflowY: 'auto',
          overflowX: 'hidden',
        });
      },
    });
    tl.to(logo, {
      rotate: 360 * 3,
      duration: 3,
      ease: 'Power4.inOut',
    })
      .to(letters, {
        opacity: 0,
        stagger: 0.1,
      })
      .fromTo(
        introWrapper,
        {
          clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
        },
        {
          clipPath: 'polygon(0 100%, 100% 100%, 100% 100%, 0% 100%)',
        }
      );
  }

  homeAnimations() {
    const overlapBanner: HTMLDivElement = document.querySelector('.overlap-banner')!;
    const comingSectionBlock: HTMLDivElement = document.querySelector(
      '.image-content_component.is-second'
    )!;
    const testimonialSection: HTMLDivElement = document.querySelector(
      '.testimonial-banner .testimonial_component'
    )!;
    const testimonials: Element[] = [
      ...document.querySelectorAll('.testimonial_list .testimonial'),
    ]!;
    const ctaBanner: HTMLDivElement = document.querySelector('.cta-banner')!;
    // const hoverImages = document.querySelectorAll('.expertise-banner .image-logo');

    this.cropInImage();
    if (overlapBanner && comingSectionBlock) {
      this.overlapBannerAnimation(comingSectionBlock, overlapBanner);
    }
    // TODO: Add a bit of velocity
    this.partnersRowsAnimation();
    if (testimonials?.length > 0) {
      this.testimonials(testimonials, testimonialSection);
    }
    if (ctaBanner) {
      const imgWrapper: HTMLDivElement = ctaBanner.querySelector(
        '.background-image-wrapper.is-curved'
      )!;
      this.cropOutImage(imgWrapper, ctaBanner);
    }
  }
  expertiseAnimations() {
    const imageBannerWrapper: HTMLDivElement = document.querySelector('.image-banner')!;
    const imageBanner: NodeListOf<HTMLImageElement> =
      imageBannerWrapper?.querySelectorAll('.background-image')!;
    const logoWrapper: HTMLDivElement = document.querySelector('.text-banner')!;
    const logo: SVGElement = logoWrapper.querySelector('.svg-star')!;
    const testimonialSection: HTMLDivElement = document.querySelector(
      '.testimonial-banner .testimonial_component'
    )!;
    const testimonials: Element[] = [
      ...document.querySelectorAll('.testimonial_list .testimonial'),
    ]!;

    this.cropInImage();
    if (imageBannerWrapper && imageBanner.length > 0) {
      this.imageBannerAnimation(imageBanner[1], imageBannerWrapper);
    }
    if (logo) {
      logoWrapper.style.overflow = 'hidden';
      logoWrapper.querySelector('.text-banner_component')!.style.overflow = 'hidden';
      this.logoSpin(logo, logoWrapper);
    }
    if (testimonials?.length > 0) {
      this.testimonials(testimonials, testimonialSection);
    }
    this.partnersRowsAnimation();
  }
  blogAnimations() {
    const listWrapper: HTMLDivElement = document.querySelector('.article_list')!;
    const listItems = document.querySelectorAll('.article_list .article_link');

    if (listWrapper && this.device === 'desktop') {
      const imgWrapper = document.createElement('div');
      imgWrapper.classList.add('img-wrapper');
      const img = document.createElement('img');
      img.src = listItems[0].querySelector('img')?.src;
      imgWrapper.appendChild(img);
      listWrapper.appendChild(imgWrapper);
      imgWrapper.style.cssText = `
        position: absolute;
        top: 0;
        left: 0;
        width: 288px;
        height: 395px;
        opacity: 0;
        pointer-events: none;
        user-select: none;
        z-index: 100;
        border-radius: 15px;
        overflow: hidden;
        // top: 50%;
        transform: translateY(-50%) translateX(-50%);
      `;
      img.style.cssText = `[]
        width: 100%;
        height: 100%;
        object-fit: cover;
      `;
      listWrapper.addEventListener('mousemove', (e) => {
        gsap.to(imgWrapper, {
          opacity: 1,
          left: e.pageX,
          top: e.pageY,
          duration: 0.3,
        });
      });
      listWrapper.addEventListener('mouseleave', () => {
        gsap.to(imgWrapper, { opacity: 0, duration: 0.3 });
      });
      listItems.forEach((item) => {
        item.addEventListener('mouseenter', () => {
          img.src = item.querySelector('img')?.src;
          gsap.to(imgWrapper, {
            opacity: 1,
            duration: 0.3,
          });
        });
        item.addEventListener('mouseleave', () => {
          gsap.to(imgWrapper, { opacity: 0, duration: 0.3 });
        });
      });
    }
  }
  aboutAnimations() {
    const horizontalScroll = document.querySelectorAll('.horizontal-banner_scroller');
    if (horizontalScroll.length > 0) {
      horizontalScroll.forEach((el) => {
        el.style.overflow = 'unset';
        this.horizontalScrollAnimation(el, el);
      });
    }
    this.partnersRowsAnimation();
  }
  partnersAnimations() {
    this.cropInImage();
    this.partnersRowsAnimation();
  }
  gtfoCollection() {
    this.cropInImage();
  }

  checkPage() {
    const path = window.location.pathname;

    if (path === '/' || path === '') {
      return 'home';
    }
    if (path.includes('expertise')) {
      return 'expertise';
    }
    if (path.includes('blog')) {
      return 'blog';
    }
    if (path.includes('guides')) {
      return 'guides';
    }
    if (path.includes('about')) {
      return 'about';
    }
    if (path.includes('partners')) {
      return 'partners';
    }
    if (path.includes('gtfo-collection')) {
      return 'gtfo-collection';
    }
  }
  getDevice() {
    if (window.matchMedia('(max-width: 767px)').matches) {
      return 'mobile';
    }
    return 'desktop';
  }
  setupResizeListener() {
    window.addEventListener('resize', () => {
      const newDevice = this.getDevice();
      if (newDevice !== this.device) {
        this.device = newDevice;
        this.init();
      }
    });
  }

  init() {
    const page = this.checkPage();
    const itemStr = localStorage.getItem('hasCodeRunBefore');
    if (itemStr) {
      const item = JSON.parse(itemStr);
      const now = new Date();
      if (now.getTime() > item.expiry) {
        localStorage.removeItem('hasCodeRunBefore');
      }
    }
    if (localStorage.getItem('hasCodeRunBefore') === null) {
      this.createIntroDom().then((introWrapper) => {
        this.introAnimation(introWrapper);
      });
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 1); // 24 hours from now
      localStorage.setItem(
        'hasCodeRunBefore',
        JSON.stringify({ value: 'true', expiry: expirationDate.getTime() })
      );
    } else {
      gsap.set(document.querySelector('.page-wrapper.is-loading'), {
        opacity: 1,
      });
      gsap.set(document.querySelector('.page-wrapper'), {
        overflowY: 'auto',
        overflowX: 'hidden',
      });
      document.querySelector('.page-wrapper')?.classList.remove('is-loading');
    }
    if (page === 'home') {
      this.homeAnimations();
    } else if (page === 'expertise') {
      this.expertiseAnimations();
    } else if (page === 'blog' || page === 'guides') {
      this.blogAnimations();
    } else if (page === 'about') {
      this.aboutAnimations();
    } else if (page === 'partners') {
      this.partnersAnimations();
    } else if (page === 'gtfo-collection') {
      this.gtfoCollection();
    }
  }
}

export default GTFO;
